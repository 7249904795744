@import './reset';

$primary-color: #AFAFAE;
$bg-color: #333333;
$accent-color: #EC7404;

body {
  font-family: 'Bebas Neue', cursive;
  background-color: $bg-color;
  color: $primary-color;
}

::-moz-selection {
  background: $accent-color;
}
::selection {
  background: $accent-color;
}

// Home - Desktop
.home {
  &-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    @media screen and (max-width: 760px) {
      height: auto;
      overflow: auto;
    }
  }
  &-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    @media screen and (max-width: 760px) {
      position: relative;
      top: auto;
      right: auto;
      height: auto;
      width: 100%;
    }
  }
  &-content {
    position: relative;
    height: 100%;
    width: 100%;
    @media screen and (max-width: 760px) {
      height: auto;
    }
    &-wrapper {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: $bg-color;
      z-index: 4;
      @media screen and (max-width: 760px) {
        height: auto;
        position: relative;
        padding-bottom: 8%;
      }
    }
    &-inner {
      position: relative;
      height: 100%;
      max-width: 380px;
      text-align: center;
      margin: 0 auto;
      padding-top: 8%;
      @media screen and (max-width: 860px) {
        max-width: 280px;
      }
      h1 {
        font-size: 72px;
        font-weight: normal;
        @media screen and (max-width: 860px) {
          font-size: 36px;
        }
      }
    }
    &__items {
      width: 100%;
      padding: 8% 0;
      li {
        padding-top: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid $primary-color;
        opacity: 0;
        a {
          text-decoration: none;
          font-size: 32px;
          color: $primary-color;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
    &__img {
      width: 172px;
      opacity: 0;
      @media screen and (max-height: 720px) {
        width: 82px;
      }
      @media screen and (max-width: 480px) {
        width: 98px;
      }
    }
  }
  &-footer {
    position: absolute;
    bottom: 42px;
    left: 0;
    &__text {
      text-decoration: none;
      color: $primary-color;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
    }
    &__mob {
      display: none;
      padding: 16px 0;
      text-align: center;
    }
    @media screen and (max-width: 760px) {
      display: none;
      &__mob {
        display: block;
      }
    }
  }
}

// Gallery - All
.gallery {
  &-close {
    position: absolute;
    right: 85px;
    top: 4%;
    z-index: 10;
    @media screen and (max-width: 724px){
      right: 4%;
    }
  }
  &-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .swiper {
      &-container {
        position: relative;
        overflow: hidden;
        margin: auto;
        height: 100%;
      }
      &-go-page {
        text-align: center;
        span {
          font-size: 18px;
          color: rgba($primary-color, .6)
        }
        h4 {
          font-size: 28px;
        }
      }
      &-slide {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-lazy-preloader {
        border: 5px solid rgba($primary-color, .2);
        border-radius: 50%;
        border-top: 5px solid $primary-color;
        width: 42px;
        height: 42px;
        animation: spin 1s linear infinite;
      }
    }
    h2 {
      position: absolute;
      font-size: 32px;
      left: 85px;
      bottom: 20%;
      @media screen and (max-width: 724px) {
        display: none;
      }
    }
  }
  &-next, &-prev {
    position: absolute;
    top: 50%;
    margin-top: -25px;
    cursor: pointer;
    z-index: 10;
    @media screen and (max-width: 724px) {
      display: none;
    }
  }
  &-next {
    right: 85px;
  }
  &-prev {
    left: 85px;
  }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}